import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "homework-setup"
    }}>{`Homework setup`}</h1>
    <p>{`There's a couple things you should set up before the workshop. Experience has shown setting this up during the workshop distracts from exercises.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Create a Netlify account`}</strong>{` go to `}<a parentName="p" {...{
            "href": "https://netlify.com"
          }}>{`netlify.com`}</a>{` and follow their signup instructions. You shouldn't need to pay.`}</p>
        <ol parentName="li">
          <li parentName="ol"><strong parentName="li">{`Install netlify cli`}</strong>{` Netlify's CLI tool is great. Install it with `}<inlineCode parentName="li">{`npm i -g netlify-cli`}</inlineCode>{` or `}<inlineCode parentName="li">{`yarn global add netlify-cli`}</inlineCode></li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Create a Vercel account`}</strong>{` go to `}<a parentName="p" {...{
            "href": "https://vercel.com"
          }}>{`vercel.com`}</a>{` and follow their signup instructions. You shouldn't need to pay.`}</p>
        <ol parentName="li">
          <li parentName="ol"><strong parentName="li">{`Install vercel cli`}</strong>{` Vercel has a fantastic CLI tool. Install it with `}<inlineCode parentName="li">{`npm i -g vercel`}</inlineCode>{` or `}<inlineCode parentName="li">{`yarn global add vercel`}</inlineCode></li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Install gatsby-cli`}</strong>{` with `}<inlineCode parentName="p">{`npm i -g gatsby-cli`}</inlineCode>{` or `}<inlineCode parentName="p">{`yarn global add gatsby-cli`}</inlineCode></p>
      </li>
    </ol>
    <h2 {...{
      "id": "other-housekeeping"
    }}>{`Other housekeeping`}</h2>
    <p>{`After you've created your accounts and configured the CLI tools, make sure your computer can do the following:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`JavaScript development.`}</strong>{` Make sure you can run `}<inlineCode parentName="p">{`create-react-app something`}</inlineCode>{`. If that works, your computer has all pre-requisites installed.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Git.`}</strong>{` Just in case you don't have it yet, make sure your computer works with git. We've had folks switch from MacOS to Windows just before the workshop before and then nothing worked. No fun.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`A text editor.`}</strong>{` Have an editor set up for JavaScript development. I like VSCode, but anything should do.`}</p>
      </li>
    </ol>
    <p>{`I'll be using a Mac and giving instrutions using the terminal. As long as you can follow NPM install and Git instructions, stick with the tools you're used to.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      